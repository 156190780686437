import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../../utils/utilities';
import UserLock from '../../assets/images/user-lock-svgrepo.svg';
import Handshake from '../../assets/images/handshake-svgrepo.svg';
import MoneyFinance from '../../assets/images/money-finance-business.svg';
import ShareIcon from '../../assets/images/share-android-svgrepo.svg';
import payIcon from '../../assets/images/pay.svg';
import wpIcon from '../../assets/images/whatsapp.svg';
import newsletterIcon from '../../assets/images/newsletter.svg';
import blogsIcon from '../../assets/images/blogs.svg';
import arrowIcon from '../../assets/images/arrow.svg';
import { Link } from 'react-router-dom';
import { currencyFormat, formatDateTime } from '../../utils/utilities';

function Dashboard() {
    const { userData } = useAuth();
    const [loanDetails, setLoanDetails] = useState([]);

    useEffect(() => {
        if (userData?.LoanAccountDetails) {
            setLoanDetails(userData.LoanAccountDetails);
        }
    }, [userData]);

    const hours = new Date().getHours();
    const timeOfDay = hours < 12 ? 'Morning' : hours < 17 ? 'Afternoon' : 'Evening';

    // Memoize calculations to prevent unnecessary recalculations
    const principalBalance = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            if (loan["Total Principal Balance"] !== 'NULL') {
                return acc + parseFloat(loan["Total Principal Balance"] || 0);
            }
            return acc;
        }, 0);
    }, [loanDetails]);

    const savingsBalances = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            const balance = parseFloat(loan["Balance"] || 0);
            switch (loan["Balance Type"]) {
                case "Savings Balance":
                    acc.totalSavingsBalance += balance;
                    break;
                case "Ruwa Stands Balance":
                    acc.totalRuwaStandsBalance += balance;
                    break;
                case "Housing Community Balance":
                    acc.totalHousingCommunityBalance += balance;
                    break;
                case "Student Savings Balance":
                    acc.totalStudentSavingsBalance += balance;
                    break;
                case "Student Shares Balance":
                    acc.Totalstudentsharesbalance += balance;
                    break;
                default:
                    break;
            }
            return acc;
        }, {
            totalSavingsBalance: 0,
            totalRuwaStandsBalance: 0,
            totalHousingCommunityBalance: 0,
            totalStudentSavingsBalance: 0,
            Totalstudentsharesbalance: 0
        });
    }, [loanDetails]);

    const sharesBalances = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            if (loan["Total Shares Balance"] !== 'NULL') {
                return acc + parseFloat(loan["Total Shares Balance"] || 0);
            }
            return acc;
        }, 0);
    }, [loanDetails]);

    const sharesBalancesGBP = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            const balance = parseFloat(loan["Balance"] || 0);
            switch (loan["Balance Type"]) {
                case "Shares Balance (GBP)":
                    acc.balance += balance;
                    break;
                default:
                    break;
            }
            return acc;
        }, {
            balance: 0,
        });
    }, [loanDetails]);

    const formatDateTimestr = useMemo(() => {
        if (loanDetails.length > 0) {
            // const createdDate = loanDetails[0]['Created Date'];
            const createdDateTime = loanDetails[0]['Created DateTime'];
            return formatDateTime(`${createdDateTime}`);
        }
        return '';
    }, [loanDetails]);

    const hasBalance = (balance) => balance !== null && !isNaN(balance) && balance !== 0;

    // Helper function to render balance items
    const renderBalanceItem = (label, balance, className = '') => (
        hasBalance(balance) && (
            className ? (
                <div className={`list-box ${className}`}>
                    <div className="list-detail">
                        <p>{label}</p>
            {className=='box-gbp-shares-balance'?<h3>{currencyFormat(balance,'GBP')}</h3>:<h3>{currencyFormat(balance)}</h3>}
                    </div>
                </div>
            ) : (
                <div className="list-detail">
                    <p>{label}</p>
                    <h3>{currencyFormat(balance)}</h3>
                </div>
            )
        )
    );
    

    return (
        <section className="dashboard-page">
            <div className='dashboard-inr menuBar-scroll bg-gradient'>
                <div className='dashboard-wrapper'>
                    <h3 className='greeting-text'>Hi {userData?.AccountInformation?.accoutholdername}, Good {timeOfDay}!</h3>
                    <div className='dashboard-listing'>
                        {renderBalanceItem('Total Principal Balance', principalBalance, 'box-principal-balance')}
                        
                        {(hasBalance(savingsBalances.totalSavingsBalance) || 
                          hasBalance(savingsBalances.totalRuwaStandsBalance) || 
                          hasBalance(savingsBalances.totalHousingCommunityBalance) || 
                          hasBalance(savingsBalances.totalStudentSavingsBalance) || 
                          hasBalance(savingsBalances.Totalstudentsharesbalance)) && (
                            <div className='list-box box-total-savings-balance'>
                                {renderBalanceItem('Savings Balance', savingsBalances.totalSavingsBalance)}
                                {renderBalanceItem('Ruwa Stands Balance', savingsBalances.totalRuwaStandsBalance)}
                                {renderBalanceItem('Housing Community Balance', savingsBalances.totalHousingCommunityBalance)}
                                {renderBalanceItem('Student Savings Balance', savingsBalances.totalStudentSavingsBalance)}
                                {renderBalanceItem('Student Shares Balance', savingsBalances.Totalstudentsharesbalance)}
                            </div>
                        )}

                        {renderBalanceItem('Total Shares Balance', sharesBalances, 'box-total-student-shares-balance')}
                        {renderBalanceItem('Total Shares Balance (GBP)', sharesBalancesGBP.balance, 'box-gbp-shares-balance')}
                        
                        {formatDateTimestr && (
                            <div className='notes-wrapper'>
                                <span>The figures displayed in this application are as of: <strong>{formatDateTimestr}</strong></span>
                            </div>
                        )}
                    </div>

                    <div className='box-wrapper'>
                        <DashboardLink to="/account-details" icon={UserLock} label="Personal Details" />
                        <DashboardLink to="/loans" icon={Handshake} label="Loan Account" />
                        <DashboardLink to="/savings" icon={MoneyFinance} label="Savings Account" />
                        <DashboardLink to="/shares" icon={ShareIcon} label="Shares Account" />
                    </div>
                    <br/>
                    <div className='external-link-wrapper'>
                        <h3>Useful Links</h3>
                        <DashboardLink to="https://ewesacco.org/how-to-pay" icon={payIcon} label="How To Pay" openNewtab={true} info="Click here to learn more about your payment options to EWE SACCO:"/>
                        <DashboardLink to="https://ewesacco.org/blogs" icon={blogsIcon} label="EWE SACCO Blog" openNewtab={true} info="Keep up to date with EWE SACCO through our Blog at:"/>
                        <DashboardLink to="https://ewesacco.org/newsletter" icon={newsletterIcon} label="Newsletter" openNewtab={true} info="Keep up to date with EWE SACCO events through our Newsletter at:"/>
                        <DashboardLink to="https://chat.whatsapp.com/BqD2RmwKqc5CQkEvn3vtGb" icon={wpIcon} label="History Makers" openNewtab={true} info="Follow our WhatsApp groups for History Makers at:"/>
                        <DashboardLink to="https://chat.whatsapp.com/GauEZ5ArBBXJicYkdh97M0" icon={wpIcon} label="Housing Schemes" openNewtab={true} info="Follow our WhatsApp groups for Housing Schemes at:"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Reusable component for dashboard links
const DashboardLink = ({ to, icon, label, openNewtab = false, info="" }) => (
    <div className="dashboard-box">
        {openNewtab ? (
            <>
            <div className="external-link-inr">
                <div className='external-link-icon'>
                    <img src={icon} alt={`${label} icon`} />
                </div>
                <div className='external-link-content'>
                    <p>
                        <span>{info} </span>
                        <a href={to} target="_blank" rel="noopener noreferrer" >{label} <img src={arrowIcon} alt={`Arrow icon`} /></a>
                    </p>
                </div>
            </div>
            </>
        ) : (
            <>
            <Link to={to}>
                <div className="dash-box-inr">
                    <img src={icon} alt={`${label} icon`} />
                    <p>{label}</p>
                </div>
            </Link>
            </>
        )}
    </div>
);

export default Dashboard;
